import React from "react";

import { Icon } from "../shared/Icon/Icon";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContextProvider/AuthContext";
import SearchInput from "../shared/SearchInput/SearchInput";

import "./Header.scss";
import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";

const Header = () => {
  const navigate = useNavigate();
  const handleSignOut = useAuthContext().handleSignOut;
  const isMobile = useResponsiveDimensions().isMobile;

  return (
    <div className="Header">
      <div className="Header__logo-container">
        <Icon
          className="Header__logo"
          onClick={() => {
            navigate("/");
          }}
          icon="maia-logo"
        />
        <p className="Header__logo-label">Accounts</p>
      </div>

      <div className="Header__right">
        {!isMobile && <SearchInput />}
        <div
          className="Header__logOut"
          onClick={() => {
            handleSignOut();
          }}
        >
          <Icon
            className="Header__logOut-icon"
            onClick={() => {}}
            icon="logout"
          />
          <div className="Header__logOut-label">Log Out</div>
        </div>
      </div>
    </div>
  );
};

export default Header;
