import React, { useState } from "react";

import { Link, useLocation } from "react-router-dom";
import { TopBarLinks } from "../../api/TopBarLinks";

import cs from "classnames";

import { useAuthContext } from "../../context/AuthContextProvider/AuthContext";
import profilePic from "../../assets/images/blank-profile-image.png";
import { Icon } from "../shared/Icon/Icon";
import PopUp from "../shared/PopUp/PopUp";
import FileUpload from "../shared/FileUpload/FileUpload";
import { handleFileUpload } from "../../lib/FirebaseFunctions/handleFileUpload";
import { getFileFromFirebase } from "../../lib/FirebaseFunctions/getFileFromFirebase";
import { updateProfile } from "firebase/auth";
import { auth } from "../../configFirebase";
import { useGlobalContext } from "../../context/GlobalContextProvider/GlobalContext";

import "./Topbar.scss";

const TopBar = () => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [fileUploadError, setFileUploadError] = useState("");
  const location = useLocation();
  const activePath = location.pathname;
  const user = useAuthContext().user;
  const updateUserLocalStorage = useAuthContext().updateUserLocalStorage;
  const showLoader = useGlobalContext().showLoader;
  const hideLoader = useGlobalContext().hideLoader;
  const currentUser = auth.currentUser;

  const handleUploadImage = (files: any) => {
    if (files[0] !== undefined) {
      showLoader();

      const storageReference = `users/profile_picture/${user.uid}/${user.uid}.png`;

      handleFileUpload(files[0], storageReference)
        .then((res) => {
          getFileFromFirebase(res.ref)
            .then((res) => {
              if (currentUser !== null) {
                updateProfile(currentUser, {
                  photoURL: res,
                })
                  .then((response) => {
                    updateUserLocalStorage();
                    hideLoader();
                    setShowPopUp(false);
                  })
                  .catch((error) => {
                    setFileUploadError(error.message);
                    setTimeout(() => {
                      setFileUploadError("");
                    }, 7000);
                    hideLoader();
                  });
              }
            })
            .catch(() => {});
        })
        .catch((error) => {
          setFileUploadError(error.message);
          setTimeout(() => {
            setFileUploadError("");
          }, 7000);
          hideLoader();
        });
    }
  };

  return (
    <>
      <PopUp showPopUp={showPopUp} onIconClick={() => {}}>
        <FileUpload
          errorMessage={fileUploadError}
          acceptedFileType={{ "image/png": [".png", ".jpg", ".jpeg"] }}
          onSubmit={(files) => {
            handleUploadImage(files);
          }}
          onCancelClick={() => {
            setShowPopUp(false);
          }}
          subtitle="Please upload a profile picture"
          title="File Upload"
        />
      </PopUp>
      <div className="TopBar">
        <div className="TopBar__profile">
          <div className="TopBar__profile-picture--container">
            <img
              style={{ objectFit: "cover" }}
              className="TopBar__profile-picture"
              src={user.photoURL === "" ? profilePic : user.photoURL}
              alt="Profile Picture"
            />
            <div
              className="TopBar__profile-icon--container"
              onClick={() => {
                setShowPopUp(true);
              }}
            >
              <Icon
                className="TopBar__profile-icon"
                onClick={() => {}}
                icon="camera"
              />
            </div>
          </div>
          <div className="TopBar__profile-container">
            <p className="TopBar__profile-name">
              {!user.displayName ? "Null" : user.displayName}
            </p>
            <p className="TopBar__profile-email">{user.email}</p>
          </div>
        </div>
        <div className="TopBar__links">
          {TopBarLinks.map((link) => {
            return (
              <Link className="TopBar__link" key={link.path} to={link.path}>
                <p
                  className={cs(
                    "TopBar__link-label",
                    activePath === link.path && "TopBar__link-label--active"
                  )}
                >
                  {" "}
                  {link.name}
                </p>
                <div
                  className={cs(
                    "TopBar__link-border",
                    activePath === link.path && "TopBar__link-border--active"
                  )}
                ></div>
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TopBar;
