import React, { useState } from "react";
import { PrivateRoutes, PublicRoutes } from "./AppRoutes";
import { Route, Routes as Routings } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { useAuthContext } from "../context/AuthContextProvider/AuthContext";

export const Routes = () => {
  const user = useAuthContext().user;

  return (
    <>
      <Routings>
        <Route element={<PrivateRoute user={user} />}>
          {PrivateRoutes.map((r) => (
            <Route element={r.element} key={r.path} path={r.path} />
          ))}
        </Route>

        <Route element={<PublicRoute />}>
          {PublicRoutes.map((r) => (
            <Route element={r.element} key={r.path} path={r.path} />
          ))}
        </Route>
      </Routings>
    </>
  );
};
