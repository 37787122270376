import React, { useState } from "react";

import { useDropzone, Accept } from "react-dropzone";
import { Icon } from "../Icon/Icon";
import Button from "../Button/Button";
import cs from "classnames";
import apkIcon from "../../../assets/images/apk-icon.png";

import "./FileUpload.scss";

interface FileUploadProps {
  onSubmit: (files: any) => void;
  title?: string;
  subtitle?: string;
  onCancelClick: () => void;
  acceptedFileType?: Accept;
  errorMessage?: string;
}

const FileUpload = (props: FileUploadProps) => {
  const [files, setFiles] = useState<any>([]);
  const [showDiv, setShowDiv] = useState("");

  const { getRootProps, getInputProps } = useDropzone({
    accept: props.acceptedFileType,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  return (
    <div className="FileUpload">
      <div className="FileUpload__header">
        <h5 className="FileUpload__title">{props.title}</h5>
        <h5 className="FileUpload__subtitle">{props.subtitle}</h5>
      </div>
      <div className="FileUpload__body">
        <div className="FileUpload__input-container" {...getRootProps()}>
          <input className="FileUpload__input" {...getInputProps()} />

          <Icon
            className="FileUpload__paragraph"
            onClick={() => {}}
            icon="drop-files"
          />
        </div>
        {files.map((file: any) => (
          <div
            className="FileUpload__image-container"
            key={file.name}
            onMouseEnter={(e) => {
              setShowDiv("show");
            }}
            onMouseLeave={(e) => {
              setShowDiv("");
            }}
          >
            <img
              className="FileUpload__image"
              src={file.type.startsWith("application") ? apkIcon : file.preview}
              alt="preview"
            />
            <div
              className={cs(
                "FileUpload__remove",
                `FileUpload__remove--${showDiv}`
              )}
            >
              <button
                className="FileUpload__remove-button"
                onClick={(e) => {
                  setFiles([]);
                }}
              >
                Remove
              </button>
              <p className="FileUpload__remove-paragraph">{file.name}</p>
            </div>
          </div>
        ))}
      </div>

      {props.errorMessage && (
        <p className="FileUpload__error">{props.errorMessage}</p>
      )}

      <div className="FileUpload__footer">
        <Button
          onClick={() => {
            setFiles([]);
            props.onCancelClick();
          }}
          label="Cancel"
          className="Button__dark FileUpload__button"
        />
        <Button
          onClick={() => {
            props.onSubmit(files);
          }}
          label="Submit"
          className="Button__light"
        />
      </div>
    </div>
  );
};

export default FileUpload;
