import { useFormik } from "formik";
import * as Yup from "yup";

const DisplayNameSchema = Yup.object().shape({
  display_name: Yup.string().required("Please enter a Display Name"),
});

export const useDisplayNameFormik = (opts: {
  onSubmit?: any;
  initialValues?: any;
}) => {
  return useFormik({
    initialValues: {
      display_name: "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: DisplayNameSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
