import { useFormik } from "formik";
import * as Yup from "yup";

const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter an Email")
    .email("Enter a valid format of email address"),
});

export const useEmailFormik = (opts: {
  onSubmit?: any;
  initialValues?: any;
}) => {
  return useFormik({
    initialValues: {
      email: "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: EmailSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
