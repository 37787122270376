import React from "react";

import { Icon } from "../Icon/Icon";

import "./FormCard.scss";

interface FormCardPropsType {
  children: React.ReactNode;
  label?: string;
}

const FormCard = (props: FormCardPropsType) => {
  const { children, label } = props;

  return (
    <div className="FormCard">
      <div className="FormCard__header">
        <Icon
          className="FormCard__header-logo"
          onClick={() => {}}
          icon="maia-logo"
        />

        <p className="FormCard__header-title">{label} </p>
      </div>
      <div className="FormCard__container">
        <div className="FormCard__content">{children}</div>
      </div>
    </div>
  );
};

export default FormCard;
