import React, { useEffect, useState } from "react";

import { AuthContext, AuthContextValuesType } from "./AuthContext";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "../../configFirebase";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../GlobalContextProvider/GlobalContext";

interface AuthContextProviderPropsType {
  children: React.ReactNode;
}

export const AuthContextProvider = (props: AuthContextProviderPropsType) => {
  const [userError, setUserError] = useState("");
  const hideLoader = useGlobalContext().hideLoader;
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user")!!);

  useEffect(() => {
    if (user !== null) {
      auth.onAuthStateChanged((user) => {
        localStorage.setItem("user", JSON.stringify(user));
      });
    }
  }, [user]);

  const updateUserLocalStorage = () => {
    if (user !== null) {
      auth.onAuthStateChanged((user) => {
        localStorage.setItem("user", JSON.stringify(user));
      });
    }
  };

  const handleSignIn = async (email: any, password: any) => {
    await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        localStorage.setItem("user", JSON.stringify(userCredential.user));
        navigate("/");
        hideLoader();
      })
      .catch((error) => {
        setUserError(error.code);
        hideLoader();
        setTimeout(() => {
          setUserError("");
        }, 3000);
      });
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        localStorage.setItem("user", JSON.stringify(null));
        window.location.reload();
      })
      .catch((error) => {});
  };

  const context: AuthContextValuesType = {
    handleSignIn,
    handleSignOut,
    user,
    userError,
    updateUserLocalStorage,
  };

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  );
};
