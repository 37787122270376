import React, { useState } from "react";

import { useLoginFormik } from "./LoginFormik";
import { useAuthContext } from "../../../context/AuthContextProvider/AuthContext";
import { useGlobalContext } from "../../../context/GlobalContextProvider/GlobalContext";
import InputField from "../../../components/shared/InputField/InputField";
import Button from "../../../components/shared/Button/Button";

import "./LoginForm.scss";

const LoginForm = () => {
  const showLoader = useGlobalContext().showLoader;
  const handleSignIn = useAuthContext().handleSignIn;
  const userError = useAuthContext().userError;

  const errorMessage = userError.split("/");

  const formik = useLoginFormik({
    onSubmit: (values: any) => {
      showLoader();
      handleSignIn(values.email, values.password);
    },
  });

  return (
    <div className="LoginForm">
      <p className="LoginForm__title">Maia Accounts</p>
      <p className="LoginForm__paragraph">
        Here you can login to Maia Accounts
      </p>
      <form className="LoginForm__form" onSubmit={formik.handleSubmit}>
        <div className="LoginForm__input">
          <p className="LoginForm__input-label">Email</p>
          <InputField
            className="LoginForm__input-field"
            name="email"
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
            value={formik.values.email}
            errorText={
              formik.errors.email && formik.touched.email && formik.errors.email
            }
          />
        </div>{" "}
        <div className="LoginForm__input LoginForm__input2">
          <p className="LoginForm__input-label">Password</p>
          <InputField
            className="LoginForm__input-field"
            name="password"
            icon
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
            errorText={
              formik.errors.password &&
              formik.touched.password &&
              formik.errors.password
            }
          />
          {errorMessage && (
            <p className="LoginForm__errorMessage">
              {errorMessage[1] !== undefined &&
                errorMessage[1].replace(/-/g, " ")}
            </p>
          )}
        </div>
        <Button
          type="submit"
          style={{ width: "100%" }}
          onClick={() => {}}
          label="Login"
          className="Button__light"
        />
      </form>
    </div>
  );
};

export default LoginForm;
