import React from "react";

import "./NotFound.scss";
import Button from "../../components/shared/Button/Button";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="NotFound">
      <div className="NotFound__container">
        <div className="NotFound__content">
          <p className="NotFound__title">404</p>
          <h3 className="NotFound__subtitle">Page not Found !</h3>
          <Button
            onClick={() => {
              navigate("/");
            }}
            label="Back to Home"
            className="Button__light"
          />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
