import React, { useState, useEffect } from "react";

import { useGlobalContext } from "../../../context/GlobalContextProvider/GlobalContext";

import "./LoadingBar.scss";

const LoadingBar = () => {
  const [progress, setProgress] = useState(0);

  const isLoading = useGlobalContext().isLoading;

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setProgress(progress + 0.25);
        if (progress === 100) {
          setProgress(0);
        }
      }, 3);
    }
    if (!isLoading) {
      setProgress(0);
      return;
    }
  }, [progress, isLoading]);

  return (
    <div className="LoadingBar">
      <div
        className="LoadingBar__progress"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

export default LoadingBar;
