import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import PrivateRouteLayout from "../layout/PrivateRouteLayout/PrivateRouteLayout";

interface PrivateRoutesPropsType {
  user: any;
}

const PrivateRoute = (props: PrivateRoutesPropsType) => {
  const { user } = props;

  return (
    <>
      {user ? (
        <PrivateRouteLayout>
          <Outlet />
        </PrivateRouteLayout>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default PrivateRoute;
