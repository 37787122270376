import { getStorage, ref, uploadBytes } from "firebase/storage";

export const handleFileUpload = (file: any, storageReference: any) => {
  const storage = getStorage();
  const storageRef = ref(storage, storageReference);

  const uploadFile = uploadBytes(storageRef, file);

  return uploadFile;
};
