import React from "react";
import { RouteProps } from "react-router-dom";
import NotFound from "../pages/NotFound/NotFound";
import AuthPage from "../pages/AuthPage/AuthPage";
import EmailVerification from "../pages/EmailVerification/EmailVerification";
import NewPassword from "../pages/NewPassword/NewPassword";
import PersonalInfo from "../pages/PersonalInfo/PersonalInfo";
import Login from "../pages/Login/Login";

interface AppRoute extends RouteProps {
  path: string;
  name?: string;
  element?: React.ReactNode;
}

export const PrivateRoutes: AppRoute[] = [
  {
    path: "/",
    name: "Personal Info",
    element: <PersonalInfo />,
  },
  {
    path: "/data-privacy",
    name: "Data & Privacy",
    element: <div>Data & Privacy</div>,
  },
  {
    path: "/security",
    name: "Security",
    element: <div>Security</div>,
  },
  {
    path: "payments-subscriptions",
    name: "Payments & Subscriptions",
    element: <div>Payments & Subscriptions</div>,
  },
];

export const PublicRoutes: AppRoute[] = [
  {
    path: "/auth",
    name: "Auth Page",
    element: <AuthPage />,
  },
  {
    path: "/email-verification",
    name: "Email Verification",
    element: <EmailVerification />,
  },
  {
    path: "/new-password",
    name: "New Password",
    element: <NewPassword />,
  },
  {
    path: "/login",
    name: "Login",
    element: <Login />,
  },
  {
    path: "*",
    name: "Not Found",
    element: <NotFound />,
  },
];
