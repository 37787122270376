import React, { useEffect, useState } from "react";

import { useEmailFormik } from "./EmailFormik";
import { useGlobalContext } from "../../../context/GlobalContextProvider/GlobalContext";
import InputField from "../../../components/shared/InputField/InputField";
import Button from "../../../components/shared/Button/Button";
import { updateEmail } from "firebase/auth";

import { auth } from "../../../configFirebase";
import { useAuthContext } from "../../../context/AuthContextProvider/AuthContext";

import "./EmailForm.scss";

const EmailForm = () => {
  const [emailError, setEmailError] = useState("");
  const updateUserLocalStorage = useAuthContext().updateUserLocalStorage;
  const showLoader = useGlobalContext().showLoader;
  const hideLoader = useGlobalContext().hideLoader;

  const user = auth.currentUser;

  const formik = useEmailFormik({
    onSubmit: (values: any) => {
      showLoader();

      if (user !== null) {
        updateEmail(user, values.email)
          .then((response) => {
            updateUserLocalStorage();
            hideLoader();
          })
          .catch((error) => {
            setEmailError(
              "For security reason. Try to Log Out and Log In again !"
            );

            setTimeout(() => {
              setEmailError("");
            }, 7000);

            hideLoader();
          });
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("email", user?.email);
  }, []);

  return (
    <form className="EmailForm" onSubmit={formik.handleSubmit}>
      <div className="EmailForm__row">
        <div className="EmailForm__input-container">
          <p className="EmailForm__input-label">Email</p>
          <InputField
            placeholder="manuel@cocacola.com"
            name="email"
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
            value={formik.values.email}
            errorText={
              (formik.errors.email &&
                formik.touched.email &&
                formik.errors.email) ||
              emailError
            }
          />
        </div>
        <div className="EmailForm__button-container">
          <Button
            onClick={() => {}}
            label="Update"
            className="EmailForm__button Button__light"
            type="submit"
          />
        </div>
      </div>
    </form>
  );
};

export default EmailForm;
