import React from "react";

import Accordion from "../../components/shared/Accordion/Accordion";
import DisplayNameForm from "./DisplayNameForm/DisplayNameForm";
import EmailForm from "./EmailForm/EmailForm";

import "./PersonalInfo.scss";

const PersonalInfo = () => {
  return (
    <div className="PersonalInfo">
      <Accordion title="Basic Info" subtitle="Manage your personal data here.">
        <DisplayNameForm />
        <EmailForm />
      </Accordion>
    </div>
  );
};

export default PersonalInfo;
