import React, { useEffect } from "react";

import useGetParameterByName from "../../lib/hooks/useGetParameterByName";
import { Navigate, useLocation } from "react-router-dom";

import { useGlobalContext } from "../../context/GlobalContextProvider/GlobalContext";

import "./AuthPage.scss";

const AuthPage = () => {
  const showLoader = useGlobalContext().showLoader;
  const location = useLocation();
  const mode = useGetParameterByName("mode");

  useEffect(() => {
    showLoader();
  }, []);

  if (mode === "verifyEmail") {
    return <Navigate to={`/email-verification${location.search}`} />;
  }

  if (mode === "resetPassword") {
    return <Navigate to={`/new-password${location.search}`} />;
  }

  return (
    <div className="AuthPage">
      <div className="AuthPage__container"></div>
    </div>
  );
};

export default AuthPage;
