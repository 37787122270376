import React from "react";

import { GlobalContextProvider } from "./context/GlobalContextProvider/GlobalContextProvider";
import { AuthContextProvider } from "./context/AuthContextProvider/AuthContextProvider";
import { Routes } from "./routes/Routes";

import "./App.scss";

function App() {
  return (
    <div className="App">
      <GlobalContextProvider>
        <AuthContextProvider>
          <Routes />
        </AuthContextProvider>
      </GlobalContextProvider>
    </div>
  );
}

export default App;
