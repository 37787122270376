import React from "react";

import LoadingBar from "../../components/shared/LoadingBar/LoadingBar";
import Header from "../../components/Header/Header";
import TopBar from "../../components/TopBar/TopBar";

import "./PrivateRouteLayout.scss";

interface PrivateRouteLayoutPropsType {
  children?: React.ReactNode;
}

const PrivateRouteLayout = (props: PrivateRouteLayoutPropsType) => {
  const { children } = props;
  return (
    <div className="PrivateRouteLayout">
      <div className="PrivateRouteLayout__header">
        <LoadingBar />
        <div className="PrivateRouteLayout__header-container">
          <Header />
        </div>
      </div>
      <div className="PrivateRouteLayout__body">
        <TopBar />
        {children}
      </div>
    </div>
  );
};

export default PrivateRouteLayout;
