import React, { useState, useEffect, useRef } from "react";

import { applyActionCode } from "firebase/auth";
import { auth } from "../../configFirebase";
import FormCard from "../../components/shared/FormCard/FormCard";
import MessageTemplate from "../../components/shared/MessageTemplate/MessageTemplate";
import useGetParameterByName from "../../lib/hooks/useGetParameterByName";
import { useGlobalContext } from "../../context/GlobalContextProvider/GlobalContext";

import "./EmailVerification.scss";

const EmailVerification = () => {
  const [emailVerificationStatus, setEmailVerificationStatus] = useState("");
  const hideLoader = useGlobalContext().hideLoader;
  const actionCode = useGetParameterByName("oobCode");

  const effectRan = useRef(false);

  useEffect(() => {
    if (!effectRan.current) {
      applyActionCode(auth, actionCode)
        .then((response) => {
          setEmailVerificationStatus("success");
          hideLoader();
        })
        .catch((error) => {
          if (error.code === "auth/expired-action-code") {
            setEmailVerificationStatus("expired");
            hideLoader();
          } else {
            setEmailVerificationStatus("failed");
            hideLoader();
          }
        });
    }
    return () => {
      effectRan.current = true;
    };
  }, []);

  return (
    <div className="EmailVerification">
      <div className="EmailVerification__container">
        {emailVerificationStatus === "success" && (
          <FormCard label="Email Verification">
            <MessageTemplate
              onButtonClick={() => {}}
              icon="email-verified"
              title="Your email has been verified"
              paragraph="You can sign in now with your new account using your credentials."
              buttonLabel="Go to Login"
            />
          </FormCard>
        )}

        {emailVerificationStatus === "failed" && (
          <FormCard label="Email Verification">
            <MessageTemplate
              onButtonClick={() => {}}
              icon="something-wrong"
              title="Oops! Something went wrong"
              paragraph="Please try refreshing your page or try again later."
              buttonLabel="Go Back"
            />
          </FormCard>
        )}

        {emailVerificationStatus === "expired" && (
          <FormCard label="Email Verification">
            <MessageTemplate
              onButtonClick={() => {}}
              icon="expired-link"
              title="The link has expired"
              paragraph="The link has already been used or expired. Please request a new one."
              buttonLabel="Go Back"
            />
          </FormCard>
        )}
      </div>
    </div>
  );
};

export default EmailVerification;
