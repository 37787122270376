import { useFormik } from "formik";
import * as Yup from "yup";

const NewPasswordSchema = Yup.object().shape({
  email: Yup.string().optional(),
  new_password: Yup.string()
    .required("Enter a New Password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%?./|~>*()<])[A-Za-z\d@#$%?./|~>*()<]{8,}$/,
      "Minimum 8 characters, at least one uppercase, one lowercase letter, one number and one special character"
    ),
  confirm_password: Yup.string()
    .required("Enter a Confirm Password")
    .when("new_password", {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("new_password")],
        "Those passwords didn't match. Try again."
      ),
    }),
});

export const useNewPasswordFormik = (opts: {
  onSubmit?: any;
  initialValues?: any;
}) => {
  return useFormik({
    initialValues: {
      email: "",
      new_password: "",
      confirm_password: "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: NewPasswordSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
