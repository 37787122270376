import { useFormik } from "formik";
import * as Yup from "yup";

const LoginFormSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter an Email")
    .email("Enter a valid format of email address"),
  password: Yup.string().required("Please enter a Password"),
});

export const useLoginFormik = (opts: {
  onSubmit?: any;
  initialValues?: any;
}) => {
  return useFormik({
    initialValues: {
      email: "",
      password: "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: LoginFormSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
