import React, { useState } from "react";

import { Icon } from "../Icon/Icon";
import cs from "classnames";

import "./Accordion.scss";

interface AccordionPropsType {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
}

const Accordion = (props: AccordionPropsType) => {
  const { children, title, subtitle } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="Accordion">
      <div
        className="Accordion__head"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className="Accordion__left">
          <p className="Accordion__title">{title}</p>
          <p className="Accordion__subtitle">{subtitle}</p>
        </div>

        <Icon
          className={cs("Accordion__icon", isOpen && "Accordion__icon--isOpen")}
          onClick={() => {}}
          icon="chevron-down"
        />
      </div>

      {isOpen && <div className="Accordion__body">{children}</div>}
    </div>
  );
};

export default Accordion;
