import React from "react";

import LoadingBar from "../../components/shared/LoadingBar/LoadingBar";

import "./PublicRouteLayout.scss";

interface PublicRouteLayoutPropsType {
  children: React.ReactNode;
}

const PublicRouteLayout = (props: PublicRouteLayoutPropsType) => {
  const { children } = props;
  return (
    <div className="PublicRouteLayout">
      <div className="PublicRouteLayout__loader">
        <LoadingBar />
      </div>
      <div className="PublicRouteLayout__children">{children}</div>
    </div>
  );
};

export default PublicRouteLayout;
