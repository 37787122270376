import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA5f6hwUIs0fsZ9RmhTH5koBEo9TtxBHI4",
  authDomain: "maia-demo-356312.firebaseapp.com",
  projectId: "maia-demo-356312",
  storageBucket: "maia-demo-356312.appspot.com",
  messagingSenderId: "314607973121",
  appId: "1:314607973121:web:3253ea8ea2dc793f5fe100",
  measurementId: "G-YFWL9T7P56",
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
