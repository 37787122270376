import React, { useState } from "react";

import "./PopUp.scss";
import { Icon } from "../Icon/Icon";
import cs from "classnames";

interface PopUpPropsType {
  children?: React.ReactNode;
  closeIcon?: boolean;
  showPopUp?: boolean;
  onIconClick: () => void;
  position?: "top" | "bottom" | "left" | "right";
}

const PopUp = (props: PopUpPropsType) => {
  const { children, closeIcon, showPopUp, onIconClick, position } = props;

  return (
    <div className={cs("PopUp", showPopUp && "PopUp--show")}>
      <div className={cs("PopUp__wrapper", `PopUp__wrapper--${position}`)}>
        <div className="PopUp__container">
          {closeIcon && (
            <div className="PopUp__header">
              <Icon
                className="PopUp__header--icon"
                onClick={() => {
                  onIconClick();
                }}
                icon="close-icon"
              />
            </div>
          )}

          <div className="PopUp__body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
