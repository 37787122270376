import { createContext, useContext } from "react";

export interface AuthContextValuesType {
  handleSignIn: (email: any, password: any) => void;
  handleSignOut: () => void;
  user: any;
  userError: string;
  updateUserLocalStorage: () => void;
}

const AuthContextValues: AuthContextValuesType = {
  handleSignIn: () => {},
  handleSignOut: () => {},
  user: null,
  userError: "",
  updateUserLocalStorage: () => {},
};

export const AuthContext = createContext(AuthContextValues);

export const useAuthContext = () => useContext(AuthContext);
