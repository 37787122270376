import React from "react";

import FormCard from "../../components/shared/FormCard/FormCard";

import LoginForm from "./LoginForm/LoginForm";

import "./Login.scss";

const Login = () => {
  return (
    <div className="Login">
      <div className="Login__container">
        <FormCard label="Login">
          <LoginForm />
        </FormCard>
      </div>
    </div>
  );
};

export default Login;
