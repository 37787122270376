import React, { useEffect, useRef, useState } from "react";
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { auth } from "../../configFirebase";
import FormCard from "../../components/shared/FormCard/FormCard";
import MessageTemplate from "../../components/shared/MessageTemplate/MessageTemplate";
import NewPasswordForm from "./NewPasswordForm/NewPasswordForm";
import useGetParameterByName from "../../lib/hooks/useGetParameterByName";
import { useGlobalContext } from "../../context/GlobalContextProvider/GlobalContext";

import "./NewPassword.scss";

const NewPassword = () => {
  const [email, setEmail] = useState("");
  const [resetPasswordStatus, setResetPasswordStatus] = useState("");
  const actionCode = useGetParameterByName("oobCode");
  const showLoader = useGlobalContext().showLoader;
  const hideLoader = useGlobalContext().hideLoader;

  const effectRan = useRef(false);

  useEffect(() => {
    if (!effectRan.current) {
      verifyPasswordResetCode(auth, actionCode)
        .then((email) => {
          setEmail(email);
          setResetPasswordStatus("new-password");
          hideLoader();
        })
        .catch((error) => {
          if (error.code === "auth/expired-action-code") {
            setResetPasswordStatus("expired");
            hideLoader();
          } else {
            setResetPasswordStatus("failed");
            hideLoader();
          }
        });
    }

    return () => {
      effectRan.current = true;
    };
  }, []);

  const handleResetPassword = (confirmPassword: any) => {
    confirmPasswordReset(auth, actionCode, confirmPassword)
      .then((response) => {
        setResetPasswordStatus("success");
        hideLoader();
      })
      .catch((error) => {
        if (error.code === "auth/expired-action-code") {
          setResetPasswordStatus("expired");
          hideLoader();
        } else {
          setResetPasswordStatus("failed");
          hideLoader();
        }
      });
  };

  return (
    <div className="NewPassword">
      <div className="NewPassword__container">
        {resetPasswordStatus === "new-password" && (
          <FormCard label="New Password">
            <NewPasswordForm
              email={email}
              onFormSubmit={(confirmPassword: any) => {
                showLoader();
                handleResetPassword(confirmPassword);
              }}
            />
          </FormCard>
        )}

        {resetPasswordStatus === "success" && (
          <FormCard label="New Password">
            <MessageTemplate
              onButtonClick={() => {}}
              icon="lock"
              title="Your password has been updated"
              paragraph="You can sign in to your account with your new password."
              buttonLabel="Go to Login"
            />
          </FormCard>
        )}

        {resetPasswordStatus === "failed" && (
          <FormCard label="New Password">
            <MessageTemplate
              onButtonClick={() => {}}
              icon="something-wrong"
              title="Oops! Something went wrong"
              paragraph="Please try refreshing your page or try again later."
              buttonLabel="Go Back"
            />
          </FormCard>
        )}

        {resetPasswordStatus === "expired" && (
          <FormCard label="Email Verification">
            <MessageTemplate
              onButtonClick={() => {}}
              icon="expired-link"
              title="The link has expired"
              paragraph="The link has already been used or expired. Please request a new one."
              buttonLabel="Go Back"
            />
          </FormCard>
        )}
      </div>
    </div>
  );
};

export default NewPassword;
