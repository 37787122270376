import React, { useRef } from "react";

import { Icon } from "../Icon/Icon";

import "./SearchInput.scss";

const SearchInput = () => {
  const inputRef = useRef(null);

  const onSearchIconClick = () => {
    // inputRef.current.focus();
  };

  return (
    <div className="SearchInput">
      <Icon
        className="SearchInput__icon"
        onClick={onSearchIconClick}
        icon="search"
      />
      <input
        ref={inputRef}
        className="SearchInput__input"
        placeholder="Search in Maia Account"
      />
    </div>
  );
};

export default SearchInput;
