import React from "react";
import { Outlet } from "react-router-dom";
import PublicRoutesLayout from "../layout/PublicRouteLayout/PublicRouteLayout";

const PublicRoute = () => {
  return(
    <div className="PublicRoute">
      <PublicRoutesLayout>
        <Outlet />
      </PublicRoutesLayout>
    </div>
  )
}

export default PublicRoute