import React, { useEffect, useState } from "react";

import InputField from "../../../components/shared/InputField/InputField";
import Button from "../../../components/shared/Button/Button";

import { useDisplayNameFormik } from "./DisplayNameFormik";

import { auth } from "../../../configFirebase";
import { updateProfile } from "firebase/auth";
import { useAuthContext } from "../../../context/AuthContextProvider/AuthContext";
import { useGlobalContext } from "../../../context/GlobalContextProvider/GlobalContext";

import "./DisplayNameForm.scss";

const DisplayNameForm = () => {
  const updateUserLocalStorage = useAuthContext().updateUserLocalStorage;
  const showLoader = useGlobalContext().showLoader;
  const hideLoader = useGlobalContext().hideLoader;
  const user = auth.currentUser;

  const formik = useDisplayNameFormik({
    onSubmit: (values: any) => {
      showLoader();

      if (user !== null) {
        updateProfile(user, {
          displayName: values.display_name,
        })
          .then((response) => {
            updateUserLocalStorage();
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
          });
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("display_name", user?.displayName);
  }, []);

  return (
    <form className="DisplayNameForm" onSubmit={formik.handleSubmit}>
      <div className="DisplayNameForm__row">
        <div className="DisplayNameForm__input-container">
          <p className="DisplayNameForm__input-label">Display Name</p>
          <InputField
            placeholder="Manuel Arroyo"
            name="display_name"
            onBlur={(e: any) => formik.handleBlur(e)}
            onChange={(e: any) => formik.handleChange(e)}
            value={formik.values.display_name}
            errorText={
              formik.errors.display_name &&
              formik.touched.display_name &&
              formik.errors.display_name
            }
          />
        </div>

        <div className="DisplayNameForm__button-container">
          <Button
            onClick={() => {}}
            label="Update"
            className="DisplayNameForm__button Button__light"
            type="submit"
          />
        </div>
      </div>
    </form>
  );
};

export default DisplayNameForm;
